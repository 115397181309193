import image1 from '../assets/avatar2.jpg';
import image2 from '../assets/avatar3.jpg';
import image3 from '../assets/avatar5.jpg';
import image4 from '../assets/avatar8.jpg';

export const testimonialsData = [
    {
      image: image1,
      review: "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
      name: 'MATHEW HENDRICKSON',
      status : 'Samsung Kenya'
    },
    {
      image: image2,
      review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
      name: 'JOHN KEVIN',
      status: 'Oracle Inc'
    },
    {
      image : image3,
      review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
      name: ' Mary Dickson',
      status: "ALX, partner"
    },
    {
      image : image4,
      review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
      name: 'Frankline Hubson',
      status: "Client, Freelancer"
    }
  ];