import react from '../assets/react.png';
import html from '../assets/html.png';
import js from '../assets/javascript.png';
import sass from '../assets/sass.png';
import css from '../assets/css.png';
import php from '../assets/php.png';

export const SkillsData= [
    {
        image: react,
        about : 'REACT',
      },
    {
        image: html,
        about : 'HTML5',
      },
    {
        image: js,
        about : 'JAVASCRIPT',
      },
    {
        image: sass,
        about : 'SASS',
    },
    {
        image: css,
        about: 'CSS3',
      },
    {
        image: php,
        about : 'PHP',
      }
];


export const SchoolDetails = [
  "Lorem ipsum dolor, sit amet consectetur adipisicing elit Eum aperiam repellendus quasi vitae architecto facilis pariatur, adipisci porro quod vel",
   "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum aperiam repellendus quasi vitae architecto facilis pariatur, adipisci porro quod vel?",
   "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum aperiam repellendus quasi vitae architecto facilis pariatur, adipisci porro quod vel?",
   "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum aperiam repellendus quasi vitae architecto facilis pariatur, adipisci porro quod vel?",
     "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum aperiam repellendus quasi vitae architecto facilis pariatur, adipisci porro quod vel?"
]